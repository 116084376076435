import {useTranslation} from 'next-i18next'
import {TranslatedSlug} from 'types/types'
import {Segment} from 'util/slugHelpers/segment'
import {useRouter} from 'next/router'

const LanguageToggle = ({
  translatedSlugs,
}: {
  translatedSlugs: TranslatedSlug[] | undefined
}) => {
  const {i18n} = useTranslation()
  const router = useRouter()

  if (!translatedSlugs) {
    return null
  }
  const locale = i18n?.language

  const localeToDisplay = () => {
    if (locale === 'nl') {
      return 'fr'
    } else {
      return 'nl'
    }
  }

  const constructLanguageToggleUrl = () => {
    const localeToSwitchTo = locale === 'fr' ? 'nl' : 'fr'
    let url = translatedSlugs.find(slug => slug.lang === localeToSwitchTo)?.path

    if (!url) {
      url = router.asPath
    }

    if (url?.includes('singletons')) {
      // SDP detail page located in singletons folder. We cannot blindly load this url.
      // Fall back on the router path.
      url = router.asPath
    }

    // Translated slugs don't have a prefixed slash. Router.asPath does. Strip it.
    if (url?.startsWith('/')) {
      url = url?.slice(1)
    }

    // For some reason storyblok translated slugs will not contain the correct
    // translated slug. I.e. the translated slug for 'nl' may contain 'professionnel'
    // instead of 'professional'. Seems like a bug in the translated slugs plugin.

    let components = url?.split('/')
    if (!components) {
      return url
    }
    const areaComponent = url?.split('/')?.[0]
    if (!areaComponent) {
      return url
    }

    if (localeToSwitchTo === 'nl' && areaComponent === Segment.professionnel) {
      components[0] = Segment.professional
      return components.join('/')
    } else if (
      localeToSwitchTo === 'fr' &&
      areaComponent === Segment.professional
    ) {
      components[0] = Segment.professionnel
      return components.join('/')
    } else {
      return url
    }
  }

  return (
    <div>
      {/*  Individual pages contain the Xtra button. The only way to get the
      language to update is to make sure the script is completely reloaded.
      Therefore the different approach. The same is true for generating the
      canonical urls. With <Link> tags they do not get regenerated correctly. */}
      <a
        href={`https://${process.env.NEXT_PUBLIC_DOMAIN!}/${
          locale === 'fr' ? 'nl' : 'fr'
        }/${constructLanguageToggleUrl()}`}
      >
        <button
          type="button"
          className="flex text-sm font-semibold  text-dats-s3 no-underline"
        >
          {localeToDisplay().toUpperCase()}
        </button>
      </a>
    </div>
  )
}

export default LanguageToggle
